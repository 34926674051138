import React, { useState } from 'react';
import './index.css';
import { LoginProps } from './types';

// async function loginUser({ username, password }: LoginUserProps) {
//     return fetch('http://localhost:8080/login', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ username, password })
//     }).then(data => data.json())
// }

export default function Login({ setToken }: LoginProps) {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // const token = await loginUser({ username, password });

        const secureTxt = "megamart";

        if (username === secureTxt && password === secureTxt)
            setToken(secureTxt);
    }
    return(
        <div className="login-wrapper">
            <h1>Please Log In</h1>
            <form onSubmit={handleSubmit}>
                <label>
                <p>Username</p>
                <input type="text" onChange={e => setUserName(e.target.value)} />
                </label>
                <label>
                <p>Password</p>
                <input type="password" onChange={e => setPassword(e.target.value)} />
                </label>
                <div>
                <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}
