import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Home from './pages/Home';
import Ranking from './pages/Ranking';

// Containers
// const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));

// Pages
// const Home = React.lazy(() => import('./pages/Home'));
// const Ranking = React.lazy(() => import('./Pages/Ranking'));

class App extends Component {
  render() {
      return (
        <BrowserRouter>
          {/* <React.Suspense fallback={<Loading />} /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="campaign" element={<Ranking />} />
          </Routes>
      </BrowserRouter>
      );
  }
}

export default App;
