import React, { useEffect } from "react";
import CountdownTimer from '../components/CountdownTimer';
import Login from '../components/Login';
import useToken from '../components/Login/useToken';


import "../css/ranking.css"

import { RankingProps } from './types';
import { MedalEnum } from './enums';

const RankBox = ({ player }: RankingProps) => {
  return (
    <div className="rank-box">
      <img className="team-logo" src={player.teamLogo} alt="logo" />
      <img className="player-image" src={player.playerImage} alt="playerImage" />
      <div className="ranking-container">
        <h1 className="ranking"> #{player.ranking} </h1>
      </div>
      <h1 className="player-name"> {player.playerName} </h1>
    </div>);
};

const Ranking = () => {

  const { token, setToken } = useToken();

  useEffect(() => {
    // Update the document title using the browser API
    // document.body.style.backgroundColor = '#d3d3d3';
    if (token === 'megamart') 
    {
      document.body.style.backgroundImage = 'url(bg.png)';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundSize = 'cover';
    }
  });

  if(!token) {
    return <Login setToken={setToken} />
  }

  // const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  // const THIRTY_SECONDS_IN_MS = 1 * 1 * 1 * 30 * 1000;
  // const NOW_IN_MS = new Date().getTime();
  const TARGETDATE_IN_MS = new Date("2023-08-31T23:59:59").getTime();

  // const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  const players = [
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/mselaineheng.webp",
      ranking: 4,
      playerName: "mselaineheng"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "https://p16-sign-sg.tiktokcdn.com/aweme/1080x1080/tos-alisg-avt-0068/d9cb9f37c59ced45cbca53bf5bc07a94.webp?x-expires=1693562400&x-signature=Vp8UGo4%2BtHxMsnlGV1lTd53q4Jg%3D",
      ranking: 99,
      playerName: "sgzabalangshop"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/lmpd.webp",
      ranking: 6,
      playerName: "lovemisspandora"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "https://p16-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/543b301219664c498665c0d950ec170d.webp?x-expires=1693584000&x-signature=tEBxCif2N2GGx6JKs%2BNN4id%2B4nI%3D",
      ranking: 99,
      playerName: "eleganz_kurve"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "https://p16-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/6cb1cc1947fde05791607d5af7fd3531.webp?x-expires=1693584000&x-signature=wWP6dU49vRHDmlpwELjHphklXbI%3D",
      ranking: 99,
      playerName: "agnesboo"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "https://p16-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/288cea3a390afd4da4b8670e4535c6cb.webp?x-expires=1693584000&x-signature=kJS8EIbxiA82lfoQlNGCjruotdk%3D",
      ranking: 99,
      playerName: "choupijiang6"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/jhgoodthings.webp",
      ranking: 99,
      playerName: "jhgoodthings"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/ce16297b4250c2cc0aeca9e33dade083.webp?x-expires=1693544400&x-signature=ETmkG0wEXi09r4SIh%2FyPclprVGo%3D",
      ranking: 99,
      playerName: "nuttysg"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "https://p16-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/75256f29236a5b740569c6a4e162c2cd.webp?x-expires=1693584000&x-signature=Q4VZm4anUgn0iDiqqGKdHmP52KM%3D",
      ranking: 99,
      playerName: "tinycreaturehub"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/xiaowang.webp",
      ranking: 1,
      playerName: "xiaowang626"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/marilyn_chong.webp",
      ranking: 2,
      playerName: "marilyn_chong"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/shopwithjeanjean.webp",
      ranking: 3,
      playerName: "shopwithjeanjean"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/userq7on7dmthy.webp",
      ranking: 5,
      playerName: "userq7on7dmthy"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/cosykmum.webp",
      ranking: 99,
      playerName: "cosykmum"
    },
    {
      teamLogo: MedalEnum.Default,
      playerImage: "/profilepic/sgbestdeal.webp",
      ranking: 99,
      playerName: "sgbestdeal"
    },
  ];

  // const random = [
  //   {
  //     teamLogo: MedalEnum.Default,
  //     playerImage: "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg",
  //     ranking: 0,
  //     playerName: "?????"
  //   },
  //   {
  //     teamLogo: MedalEnum.Default,
  //     playerImage: "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg",
  //     ranking: 0,
  //     playerName: "?????"
  //   },
  //   {
  //     teamLogo: MedalEnum.Default,
  //     playerImage: "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg",
  //     ranking: 0,
  //     playerName: "?????"
  //   },
  //   {
  //     teamLogo: MedalEnum.Default,
  //     playerImage: "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg",
  //     ranking: 0,
  //     playerName: "?????"
  //   },
  //   {
  //     teamLogo: MedalEnum.Default,
  //     playerImage: "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg",
  //     ranking: 0,
  //     playerName: "?????"
  //   },
  // ];
  return (
    
    <>
      <div className="header">
        <h1>MegaMart.sg</h1>
        <h2>September Campaign</h2>
        <CountdownTimer targetDate={TARGETDATE_IN_MS} />
        Updated as at: 2023-09-29 10:00
      </div>
      <div id="container">
        <div>
          {
            players.sort((a, b) => { if (a.ranking > b.ranking) return 1; else return -1;}).slice(0, 5).map((player, index) => {
            // if (index === 0)
            //   player.teamLogo = MedalEnum.Gold;
            // else if (index === 1)
            //   player.teamLogo = MedalEnum.Silver;
            // else if (index === 2)
            //   player.teamLogo = MedalEnum.Bronze;
            return <RankBox key={index} player={player} />;
            })
          }
        </div>
      </div>
    </>
  );
};

export default Ranking;
